




































































import Vue from "vue";
import Component from 'vue-class-component';
import Header from "@/components/front/Header.vue";
import Footer from "@/components/front/Footer.vue";
import i18n from '@/locale/i18n';
import AllComponentsList from "@/components/ui/AllComponentsList.vue";

@Component({
  metaInfo: {
    title: i18n.t("admin.components.title").toString(),
  },
  components: {
    Header,
    AllComponentsList,
    Footer,
  },
})
export default class Components extends Vue {
}
