







































































import Component from "vue-class-component";
import Vue from "vue";
import store from "@/store";
import UserUtils from "@/utils/store/UserUtils";
import { UserDto } from "@/application/dtos/core/users/UserDto";
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("app.sidebar.dashboard").toString(),
  },
})
export default class Dashboard extends Vue {
  get currentUser(): UserDto {
    return store.state.account.user ?? ({} as UserDto);
  }
  get avatarText() {
    return UserUtils.avatarText(this.currentUser);
  }
  get avatar() {
    return store.state.account.user?.avatar ?? "";
  }
}
