var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-2 w-full"},[_c('div',{staticClass:"flex justify-between"},[_c('Dropdown',{ref:"dropdownLeft",attrs:{"right":true},on:{"click":function($event){return _vm.alert('Dropdown click')}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v("Dropdown")]},proxy:true},{key:"options",fn:function(){return [_c('button',{staticClass:"w-full text-left text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50 focus:outline-none",attrs:{"type":"button","role":"menuitem","tabindex":"-1","id":"menu-item-0"},on:{"click":function () {
            _vm.$refs['dropdownLeft'].close();
            _vm.alert('Clicked')
          }}},[_c('div',[_vm._v("Button")])]),_c('router-link',{staticClass:"w-full text-left text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50 focus:outline-none",attrs:{"to":_vm.currentRoute,"role":"menuitem","tabindex":"-1","id":"menu-item-1"},nativeOn:{"click":function($event){return (function () {
            _vm.$refs['dropdownLeft'].close();
          }).apply(null, arguments)}}},[_c('div',[_vm._v("Link")])])]},proxy:true}])}),_c('Dropdown',{ref:"dropdownRight",on:{"click":function($event){return _vm.alert('Dropdown click')}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v("Dropdown")]},proxy:true},{key:"options",fn:function(){return [_c('button',{staticClass:"w-full text-left text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50 focus:outline-none",attrs:{"type":"button","role":"menuitem","tabindex":"-1","id":"menu-item-0"},on:{"click":function () {
            _vm.$refs['dropdownRight'].close();
            _vm.alert('Clicked')
          }}},[_c('div',[_vm._v("Button")])]),_c('router-link',{staticClass:"w-full text-left text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50 focus:outline-none",attrs:{"to":_vm.currentRoute,"role":"menuitem","tabindex":"-1","id":"menu-item-1"},nativeOn:{"click":function($event){return (function () {
            _vm.$refs['dropdownRight'].close();
          }).apply(null, arguments)}}},[_c('div',[_vm._v("Link")])])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }