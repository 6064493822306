var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.to)?_c('button',{staticClass:"inline-flex space-x-2 items-center px-3 py-2 border shadow-sm text-sm font-medium rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-300",class:{
    'cursor-not-allowed opacity-75': _vm.disabled,
    'text-theme-800': !_vm.destructive,
    'text-red-700': _vm.destructive,
    'hover:bg-theme-50 focus:ring-theme-500': !_vm.disabled && !_vm.destructive,
    'hover:bg-red-50 focus:ring-red-500': !_vm.disabled && _vm.destructive
  },attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2):_c('router-link',{staticClass:"inline-flex space-x-2 items-center px-3 py-2 border shadow-sm text-sm font-medium rounded-md bg-white border-gray-300",class:{
    'cursor-not-allowed opacity-75': _vm.disabled,
    'focus:outline-none focus:ring-2 focus:ring-offset-2': !_vm.disabled,
    'text-theme-800': !_vm.destructive,
    'text-red-700': _vm.destructive,
    'hover:bg-theme-50 focus:ring-theme-500': !_vm.disabled && !_vm.destructive,
    'hover:bg-red-50 focus:ring-red-500': !_vm.disabled && _vm.destructive
  },attrs:{"to":_vm.to,"disabled":_vm.disabled,"target":_vm.target}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }