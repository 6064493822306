











































































































































































import Vue from "vue";
import Component from "vue-class-component";
import DarkModeToggle from "@/components/ui/toggles/DarkModeToggle.vue";
import LocaleSelector from "@/components/ui/selectors/LocaleSelector.vue";
import store from '@/store';
import Logo from "@/components/front/Logo.vue";
import Icon from "./Icon.vue";

@Component({
  components: {
    DarkModeToggle,
    LocaleSelector,
    Logo,
    Icon
  },
})
export default class Header extends Vue {
  droppedDown = false;
  links: any[] = [
    { path: "/", title: this.$t("front.navbar.product") },
    { path: "/pricing", title: this.$t("front.navbar.pricing") },
    { path: "/contact", title: this.$t("front.navbar.contact") },
    { path: "/components", title: this.$t("admin.components.title") },
  ];
  isCurrent(path: string): boolean {
    return this.$route.path === path;
  }
  get theme() {
    return store.state.theme.theme;
  }
}
