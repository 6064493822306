





















































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Pdf from "vue-pdf";

@Component({
  components: {
    Pdf
  }
})
export default class PdfViewer extends Vue {
  @Prop({ default: "" }) file!: any;
  @Prop({ default: "" }) fileName!: string;
  @Prop({ default: false }) editing!: boolean;
  pdfDoc = '';
  pdfPages = 0;
  pdfScale = 0.49;
  currentPage = 1;
  pageCount = 0;

  nextPage() {
    this.currentPage += 1;
  }
  prevPage() {
    this.currentPage -= 1;
  }
  downloadPdf() {
    const downloadLink = document.createElement("a");
    const name = (this.fileName ?? "document") + ".pdf";
    downloadLink.href = this.file;
    downloadLink.download = name;
    downloadLink.click();
  }
}
