












import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "./Breadcrumb.vue";

@Component({
  components: {
    Breadcrumb
  },
})
export default class PreviewBreadcrumbs extends Vue {
  get currentRoute() {
    return this.$route.path;
  }
}
