




































































import Vue from "vue";
import Component from "vue-class-component";
import DropdownWithClick from "./DropdownWithClick.vue";

@Component({
  components: {
    DropdownWithClick
  },
})
export default class PreviewDropdownsWithClick extends Vue {
  $refs!: {
    dropdownLeft: DropdownWithClick;
    dropdownRight: DropdownWithClick;
  }
  alert(message) {
    window.alert(message)
  }
  get currentRoute() {
    return this.$route.path;
  }
}
