




















































































import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import Logo from "@/components/front/Logo.vue";
import services from "@/services";
import LoadingButton from '@/components/ui/buttons/LoadingButton.vue';
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("account.forgot.title").toString(),
  },
  components: {
    ErrorModal,
    Logo,
    LoadingButton,
  },
})
export default class Forgot extends Vue {
  $refs!: {
    loadingButton: LoadingButton;
    errorModal: ErrorModal;
  };
  emailSent = false;
  email = "";
  created() {
    this.email = this.$route.query.e ? this.$route.query.e.toString() : "";
  }
  reset() {
    this.$refs.loadingButton.start();
    services.authentication
      .reset(this.email)
      .then(() => {
        this.emailSent = true;
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
}
