











































































































































import Vue from "vue";
import Component from 'vue-class-component';
import { SidebarGroup } from '@/application/sidebar/SidebarGroup';
import { Prop } from 'vue-property-decorator';
import { SideBarItem } from '@/application/sidebar/SidebarItem';
import { UserType } from '@/application/enums/core/users/UserType';
import { TenantUserRole } from '@/application/enums/core/tenants/TenantUserRole';
import store from '@/store';
import { AdminSidebar } from '@/application/sidebar/AdminSidebar';
import { AppSidebar } from "@/application/sidebar/AppSidebar";
import SidebarIcon from "./icons/SidebarIcon.vue";

@Component({
  components: {
    SidebarIcon
  }
})
export default class SidebarMenu extends Vue {
  @Prop({ default: '' }) layout!: string;
  menu: SideBarItem[] = [];
  expanded: number[] = [];
  mounted() {
    this.menu = this.layout === 'admin' ? AdminSidebar : AppSidebar;
    this.menu.forEach((group) => {
      group.items?.forEach((element, index) => {
        if (element.open) {
          this.expanded.push(index);
        } else {
          this.expanded = this.expanded.filter((f) => f !== index);
        }
      });
    });
  }
  menuItemIsExpanded(index: number) {
    return this.expanded.includes(index);
  }
  toggleMenuItem(index) {
    if (this.expanded.includes(index)) {
      this.expanded = this.expanded.filter((item) => item !== index);
    } else {
      this.expanded.push(index);
    }
  }
  isCurrent(menuItem: SideBarItem) {
    return this.$route.path?.includes(menuItem.path);
    // return this.$route.name && menuItem.pathName && this.$route.name.includes(menuItem.pathName)
  }
  allowCurrentUserType(item: SideBarItem) {
    return (!item.userTypes || item.userTypes.includes(this.currentUserType));
  }
  allowCurrentRole(item: SideBarItem) {
    return (!item.userRoles || item.userRoles.includes(this.currentUserRole));
  }
  get getMenu(): SidebarGroup[] {
    const _menu: SidebarGroup[] = [];
    this.menu
      .filter(f => this.allowCurrentUserType(f) && this.allowCurrentRole(f))
      .forEach(({ title, items }) => {
        _menu.push({
          title: title.toString(),
          items: items?.filter(f => this.allowCurrentUserType(f) && this.allowCurrentRole(f)) ?? [],
        });
      });
    return _menu.filter(f => f.items.length > 0);
  }
  get currentUserType(): UserType {
    return store.state.account.user?.type ?? UserType.Tenant;
  }
  get currentUserRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role as TenantUserRole;
  }
}
