





















































































































































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
// @ts-ignore
import { Card, createToken } from "vue-stripe-elements-plus";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import Plans from "@/components/ui/radios/PlansRadioButtons.vue"
import Logo from "@/components/front/Logo.vue";
import LoadingButton from "@/components/ui/buttons/LoadingButton.vue";
import Modal from "@/components/ui/modals/Modal.vue";
import store from "@/store";
import { LinkStatus } from "@/application/enums/core/links/LinkStatus";
import { LinkInvitationDto } from "@/application/dtos/core/links/LinkInvitationDto";
import services from "@/services";
import ConfirmModal from "@/components/ui/modals/ConfirmModal.vue";
import NumberUtils from '@/utils/shared/NumberUtils';
import { UserRegisterRequest } from '@/application/contracts/core/users/UserRegisterRequest';
import { SubscriptionCouponDto } from '@/application/dtos/core/subscriptions/SubscriptionCouponDto';
import { SubscriptionBillingPeriod } from '@/application/enums/core/subscriptions/SubscriptionBillingPeriod';
import { SubscriptionProductDto } from '@/application/dtos/core/subscriptions/SubscriptionProductDto';
import { SubscriptionPriceDto } from '@/application/dtos/core/subscriptions/SubscriptionPriceDto';
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("account.register.title").toString(),
  },
  components: {
    ErrorModal,
    Modal,
    Card,
    Plans,
    Logo,
    LoadingButton,
    ConfirmModal,
  }
})
export default class Register extends Vue {
  $refs!: {
    inputFirstName: HTMLInputElement;
    loadingButton: LoadingButton;
    payButton: LoadingButton;
    modalInvitation: Modal;
    errorModal: ErrorModal;
    cardModal: Modal;
    buttonAcceptInvitation: HTMLButtonElement;
    confirmModalRegister: ConfirmModal;
  };
  loading = false;
  user = {} as UserRegisterRequest;
  email = "";
  // verifyToken: string = "";
  stripeKey = "";
  stripeOptions: any = {};
  cardCompleted = false;
  registered = false;
  haveCoupon = false;
  couponCode = "";
  couponDescription = "";
  coupon: SubscriptionCouponDto | null = null;
  linkInvitation: LinkInvitationDto | null = null;
  linkInvitationAccepted = false;

  emailEnabled = true;
  workspaceNameEnabled = true;

  acceptTermsAndConditions = false;

  created() {
    if (this.$route.query.e) {
      this.user.email = this.$route.query.e.toString();
    }

    this.stripeKey = process.env.VUE_APP_SUBSCRIPTION_PUBLIC_KEY?.toString() ?? "";
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: this.$i18n.locale,
      },
    };
  }
  mounted() {
    this.loading = true;
    services.subscriptionProducts
      .getProducts()
      .then(() => {
        if (this.$route.query.p) {
          const product = this.products.find((f) => f.tier === Number(this.$route.query.p));
          if (product) {
            store.commit("pricing/setSelected", {
              product,
              billingPeriod: store.state.pricing.billingPeriod,
            });
          }
        }
        if (!this.selectedProduct) {
          store.commit("pricing/setBillingPeriod", SubscriptionBillingPeriod.MONTHLY);
          store.commit("pricing/setCurrency", "usd");
          store.commit("pricng/select", {
            product: this.products[0],
            billingPeriod: store.state.pricing.billingPeriod,
          });
        }

        if (this.$route.params.coupon) {
          this.haveCoupon = true;
          this.couponCode = this.$route.params.coupon;
          this.searchCoupon(false);
        } else if (this.$route.query.coupon) {
          this.haveCoupon = true;
          this.couponCode = this.$route.query.coupon.toString();
          this.searchCoupon(false);
        }
        if (this.$route.query.i) {
          services.links.getInvitation(this.$route.query.i.toString()).then((response) => {
            this.linkInvitation = response;
            this.loadLinkInvitation();
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  loadLinkInvitation() {
    if (this.linkInvitation) {
      if (this.linkInvitation.status === LinkStatus.PENDING) {
        this.$refs.modalInvitation.show();
        this.$nextTick(() => {
          this.$refs.buttonAcceptInvitation.focus();
        });
      }
    }
  }
  rejectInvitation() {
    this.$refs.modalInvitation.close();
    services.links.rejectInvitation(this.linkInvitation?.id);
    this.linkInvitation = null;
  }
  acceptInvitation() {
    this.$refs.modalInvitation.close();
    this.linkInvitationAccepted = true;
    this.user.joinedByLinkInvitation = this.linkInvitation?.id;
    this.emailEnabled = false;
    this.workspaceNameEnabled = false;
    this.user.email = this.linkInvitation?.email ?? "";
    this.user.workspaceName = this.linkInvitation?.workspaceName ?? "";
    this.$nextTick(() => {
      if (this.$refs.inputFirstName) {
        this.$refs.inputFirstName.focus();
        this.$refs.inputFirstName.select();
      }
    });
  }
  searchCoupon(showError) {
    if (!this.selectedPrice) {
      return "";
    }
    if (!this.couponCode) {
      return;
    }
    services.subscriptionManager
      .getCoupon(this.couponCode, this.selectedPrice.currency)
      .then((response: SubscriptionCouponDto) => {
        this.coupon = response;
        if (this.coupon && this.coupon.name) {
          this.couponDescription = this.coupon.name.toString();
        }
      })
      .catch((error) => {
        this.couponDescription = this.$t("account.register.invalidCoupon").toString();
        if (showError) {
          this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
        }
      });
  }
  tryRegister() {
    this.email = this.user.email;
    if (!this.selectedPrice) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("settings.subscription.plans.select"));
      return;
    }
    if (this.user.password != this.user.confirmPassword) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("api.errors.passwordMismatch"));
      return;
    }
    if (!this.acceptTermsAndConditions) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("account.register.errors.acceptTermsAndConditions"));
      return;
    }
    if (this.selectedPrice.trialDays === 0 && this.selectedPrice.price > 0) {
      this.$refs.cardModal.show();
    } else {
      this.$refs.confirmModalRegister.show(this.$t("account.register.prompts.register.title"), this.$t("shared.confirm"), this.$t("shared.back"), this.$t("account.register.prompts.register.description", [
        this.$t(this.selectedProduct?.title ?? "")
      ]));
    }
  }
  confirmedRegister() {
    this.register();
  }
  pay() {
    this.$refs.payButton.start();
    createToken()
      .then((data) => {
        if (data.error) {
          this.$refs.errorModal.show(this.$t("shared.error"), data.error.message);
        } else {
          this.register(data.token.id);
        }
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), error);
      })
      .finally(() => {
        this.$refs.payButton.stop();
        this.$refs.cardModal.close();
      });
  }
  register(cardToken = "") {
    if (!this.selectedPrice) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("account.register.errors.priceNotSelected"));
      return;
    } else if (this.selectedPrice && !this.selectedPrice.id) {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t("account.register.errors.priceNotInDatabase"));
      return;
    }
    this.user.selectedSubscription = {
      subscriptionPriceId: this.selectedPrice.id ?? "",
      subscriptionCardToken: cardToken,
      subscriptionCoupon: this.couponCode,
    };
    this.$refs.loadingButton.start();
    services.authentication
      .register(this.user)
      .then(() => {
        this.registered = true;
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), error);
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
  toggleAcceptTerms() {
    this.acceptTermsAndConditions = !this.acceptTermsAndConditions
  }
  get products(): SubscriptionProductDto[] {
    return store.state.pricing.products;
  }
  get getButtonText(): string {
    if (!this.selectedPrice) {
      return "";
    }
    return (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.ONCE ? this.$t("pricing.pay") : this.$t("pricing.subscribe")) + " " + this.priceDescription;
  }
  get selectedPrice(): SubscriptionPriceDto | undefined {
    return this.selectedProduct?.prices.find((f) => f.billingPeriod === store.state.pricing.billingPeriod && f.currency === store.state.pricing.currency) ?? this.selectedProduct?.prices.filter((f) => f.currency === store.state.pricing.currency)[0];
  }
  get billingPeriod(): string {
    if (!this.selectedPrice) {
      return "";
    }
    if (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.ONCE) {
      return this.$t("pricing.once").toString();
    } else {
      return "/ " + this.$t("pricing." + SubscriptionBillingPeriod[this.selectedPrice.billingPeriod] + "Short");
    }
  }
  get discountedPrice(): number {
    if (!this.selectedPrice) {
      return 0;
    }
    let price = this.selectedPrice.price;
    if (this.coupon) {
      if (this.coupon.amountOff && this.selectedPrice.currency === this.coupon.currency) {
        price = price - this.coupon.amountOff / 100;
      } else if (this.coupon.percentOff) {
        price = Number((price * ((100 - this.coupon.percentOff) / 100)).toFixed(2));
      }
    }
    return price;
  }
  get priceDescription(): string {
    if (!this.selectedPrice) {
      return "";
    }
    return "$" + NumberUtils.intFormat(this.discountedPrice) + " " + this.selectedPrice.currency + " " + this.billingPeriod;
  }
  get selectedProduct(): SubscriptionProductDto | null {
    return store.state.pricing.selectedProduct;
  }
}
