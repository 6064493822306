






















































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import IconEmptyResults from "@/assets/icons/IconEmptyResults.vue"
import ButtonSecondary from "../buttons/ButtonSecondary.vue";

interface Captions {
  description?: string;
  thereAreNo?: string;
  new?: string;
  routeName?: string;
  routeParams?: any;
}

@Component({
  components: {
    IconEmptyResults,
    ButtonSecondary
  }
})
export default class EmptyState extends Vue {
  @Prop({ required: true }) captions!: Captions;
  @Prop({}) to!: any;
  @Prop({}) icon!: string;
}
