





















































































































import Component from "vue-class-component";
import Vue from "vue";
import Logo from "@/components/front/Logo.vue";
import LoadingButton from "@/components/ui/buttons/LoadingButton.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import services from "@/services";
import { UserVerifyRequest } from '@/application/contracts/core/users/UserVerifyRequest';
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("account.verify.title").toString(),
  },
  components: {
    Logo,
    LoadingButton,
    ErrorModal
  }
})
export default class Verify extends Vue {
  $refs!: {
    loadingButton: LoadingButton;
    errorModal: ErrorModal;
  };
  requireName = false;
  requirePassword = true;
  user = {} as UserVerifyRequest;
  created() {
    this.user.email = this.$route.query.e ? this.$route.query.e.toString() : "";
    this.user.token = this.$route.query.t ? this.$route.query.t.toString() : "";
  }
  verify() {
    if (this.user.password !== this.user.passwordConfirm) {
      this.$refs.errorModal.show(
        this.$t("account.login.errors.passwordMissmatch")
      );
      return;
    }

    this.$refs.loadingButton.start();
    services.authentication
      .verify(this.user)
      .catch(error => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
}
