





































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class ChatSupportButton extends Vue {
  showChat() {
    // @ts-ignore
    // this.$crisp.push(["do", "chat:show"]);
    // @ts-ignore
    // this.$crisp.push(["do", "chat:open"]);
  }
}
