



























































































import Component from "vue-class-component";
import Vue from "vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import Logo from "@/components/front/Logo.vue";
import services from "@/services";
import { UserVerifyRequest } from '@/application/contracts/core/users/UserVerifyRequest';
import LoadingButton from '../../../components/ui/buttons/LoadingButton.vue';
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("account.reset.title").toString(),
  },
  components: {
    ErrorModal,
    Logo,
    LoadingButton
  },
})
export default class Verify extends Vue {
  $refs!: {
    loadingButton: LoadingButton;
    errorModal: ErrorModal;
  };
  loading = false;
  user = {} as UserVerifyRequest;
  created() {
    this.user.email = this.$route.query.e ? this.$route.query.e.toString() : "";
    this.user.token = this.$route.query.t ? this.$route.query.t.toString() : "";
  }
  reset() {
    this.loading = true;
    if (this.user.password !== this.user.passwordConfirm) {
      return;
    }
    this.$refs.loadingButton.start();
    services.authentication.verify(this.user).catch(error => {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
    }).finally(() => {
      this.$refs.loadingButton.stop();
    });
  }
}
