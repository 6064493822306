




































































import Vue from "vue";
import Component from "vue-class-component";
import Dropdown from "./Dropdown.vue";

@Component({
  components: {
    Dropdown
  },
})
export default class PreviewDropdownsSimple extends Vue {
  $refs!: {
    dropdownLeft: Dropdown;
    dropdownRight: Dropdown;
  }
  alert(message) {
    window.alert(message)
  }
  get currentRoute() {
    return this.$route.path;
  }
}
