


































































































import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/front/Header.vue";
import Footer from "@/components/front/Footer.vue";
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("front.terms.title").toString(),
  },
  components: {
    Header,
    Footer,
  },
})
export default class TermsAndConditions extends Vue { }
