

















import Vue from "vue";
import Component from "vue-class-component";
import Tabs from "./Tabs.vue";

@Component({
  components: {
    Tabs
  },
})
export default class PreviewTabsAsButtons extends Vue {
  currentTab = 0;
  selectedTab(idx) {
    this.currentTab = idx;
  }
}
