






import Vue from "vue";
import Component from "vue-class-component";
import Loading from "./Loading.vue";

@Component({
  components: {
    Loading
  },
})
export default class PreviewLoaders extends Vue {

}
