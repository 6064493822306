






































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import i18n from '@/locale/i18n';

@Component({})
export default class WarningBanner extends Vue {
  @Prop({ default: i18n.t("shared.warning") }) title!: string;
  @Prop({ default: "" }) text!: string;
  @Prop({ default: "" }) redirect!: string;
}
