











































































































































import Component from "vue-class-component";
import Vue from "vue";
import LoadingButton from "@/components/ui/buttons/LoadingButton.vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import Logo from "@/components/front/Logo.vue";
import store from "@/store";
import services from "@/services";
import { TenantDto } from '@/application/dtos/core/tenants/TenantDto';
import { UserVerifyRequest } from '@/application/contracts/core/users/UserVerifyRequest';
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("account.join.title").toString(),
  },
  components: {
    ErrorModal,
    Logo,
    LoadingButton,
  },
})
export default class JoinTenant extends Vue {
  $refs!: {
    loadingButton: LoadingButton;
    errorModal: ErrorModal;
  };
  loading = false;
  user = {} as UserVerifyRequest;
  emailDisabled = false;
  tenant = {} as TenantDto;
  requested = false;
  accepted = false;
  created() {
    this.user.token = this.$route.params.tenant ?? "";
    this.user.email = this.email;
    if (!this.email) {
      this.user.email = this.$route.query.e?.toString() ?? "";
      this.accepted = this.$route.query.a === "true";
      if (this.user.email && this.user.email !== "") {
        this.emailDisabled = true;
      }
    } else {
      this.emailDisabled = true;
    }
  }
  mounted() {
    if (this.$route.params.tenant) {
      this.loading = true;
      services.tenantUserInvitations
        .getInviteURL(this.$route.params.tenant)
        .then((response: TenantDto) => {
          this.tenant = response;
        })
        .catch((error) => {
          if (error.status === 404) {
            this.$refs.errorModal.show(this.$t("shared.invalidInvitation"));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  accept() {
    if (!this.emailDisabled && this.user.password !== this.user.passwordConfirm) {
      this.$refs.errorModal.show(this.$t("account.login.errors.passwordMissmatch"));
      return;
    }
    this.$refs.loadingButton.start();
    services.tenantUserInvitations
      .requestAccess(this.$route.params.tenant, this.user)
      .then(() => {
        this.requested = true;
      })
      .catch((error) => {
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
  logout() {
    store.commit("auth/logout");
    this.emailDisabled = false;
    this.user.email = "";
  }
  get email() {
    return store.state.account.user?.email ?? "";
  }
}
