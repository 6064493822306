


















































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import WarningBannner from "@/components/ui/banners/WarningBanner.vue";
import { SubscriptionProductDto } from "@/application/dtos/core/subscriptions/SubscriptionProductDto";
import { SubscriptionPriceDto } from "@/application/dtos/core/subscriptions/SubscriptionPriceDto";
import { SubscriptionBillingPeriod } from "@/application/enums/core/subscriptions/SubscriptionBillingPeriod";
import store from "@/store";
import services from "@/services";
import NumberUtils from "@/utils/shared/NumberUtils";
import Loading from "@/components/ui/loaders/Loading.vue";
import plans from "@/application/pricing/plans";

@Component({
  components: {
    WarningBannner,
    Loading,
  },
})
export default class Plans extends Vue {
  loading = false;
  items = [] as SubscriptionProductDto[];
  mounted() {
    this.reload();
  }
  reload() {
    this.items = [];
    this.loading = true;
    services.subscriptionProducts
      .getProducts()
      .then((response: SubscriptionProductDto[]) => {
        this.items = [];
        response?.forEach((product) => {
          this.items.push(product);
        });
        if (!this.testProducts && this.items.length === 0) {
          this.items = plans;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  billingPeriodOnce(product: SubscriptionProductDto): boolean | undefined {
    return this.getPrice(product)?.billingPeriod === SubscriptionBillingPeriod.ONCE;
  }
  productUrl(product: SubscriptionProductDto) {
    if (product.contactUs) {
      return "/contact";
    }
    return "/register";
  }
  selectProduct(product: SubscriptionProductDto) {
    store.commit("pricing/setSelected", {
      product,
      billingPeriod: this.billingPeriod,
    });
    this.$router.push(this.productUrl(product))
  }
  getPrice(product: SubscriptionProductDto): SubscriptionPriceDto | undefined {
    return product.prices.find((f) => (f.billingPeriod === this.billingPeriod || f.billingPeriod === SubscriptionBillingPeriod.ONCE) && f.currency === this.currency && f.active);
  }
  getPriceAmount(product): number {
    return this.getPrice(product)?.price ?? 0;
  }
  getPriceTrialDays(product): number {
    return this.getPrice(product)?.trialDays ?? 0;
  }
  toggleBillingPeriod() {
    if (this.billingPeriod === SubscriptionBillingPeriod.MONTHLY) {
      store.commit("pricing/setBillingPeriod", SubscriptionBillingPeriod.YEARLY);
    } else {
      store.commit("pricing/setBillingPeriod", SubscriptionBillingPeriod.MONTHLY);
    }
  }
  intFormat(value: number) {
    return NumberUtils.intFormat(value);
  }
  getPriceWithInterval(billingPeriod: SubscriptionBillingPeriod): SubscriptionPriceDto | undefined {
    let price: SubscriptionPriceDto | undefined;
    if (this.products && this.products.length > 0) {
      this.products.forEach((product) => {
        const prices = product.prices.find((f) => f.billingPeriod === billingPeriod && f.currency === this.currency && f.price > 0);
        if (prices) {
          price = prices;
        }
      });
    }
    return price;
  }
  get getYearlyDiscount(): string | undefined {
    const priceYearly = this.getPriceWithInterval(SubscriptionBillingPeriod.YEARLY);
    const priceMonthly = this.getPriceWithInterval(SubscriptionBillingPeriod.MONTHLY);
    if (priceYearly && priceMonthly) {
      const discount = 100 - (priceYearly.price * 100) / (priceMonthly.price * 12);
      if (discount !== 0) {
        return "-" + discount.toFixed(0) + "%";
      }
    }

    return undefined;
  }
  get currency() {
    return store.state.pricing.currency;
  }
  get billingPeriod() {
    return store.state.pricing.billingPeriod;
  }
  get customPlan(): SubscriptionProductDto | undefined {
    return this.products.find((f) => f.contactUs);
  }
  get products(): SubscriptionProductDto[] {
    return this.items.filter((f) => this.getPrice(f) !== undefined && f.active)
      .sort((x, y) => {
        return x.tier > y.tier ? 1 : -1;
      });
  }
  get testProducts() {
    return !this.products || this.products.filter((f) => f.id === undefined || f.id === "").length > 0;
  }
}
