






















import Vue from "vue";
import Component from "vue-class-component";
import EmptyState from "./EmptyState.vue";

@Component({
  components: {
    EmptyState
  },
})
export default class PreviewEmptyStates extends Vue {
  alert(message) {
    window.alert(message)
  }
  get currentRoute() {
    return this.$route.path;
  }
}
