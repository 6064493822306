




















import Vue from "vue";
import Component from "vue-class-component";
import ButtonPrimary from "./ButtonPrimary.vue";
import ButtonSecondary from "./ButtonSecondary.vue";
import ButtonTertiary from "./ButtonTertiary.vue";

@Component({
  components: {
    ButtonPrimary,
    ButtonSecondary,
    ButtonTertiary
  },
})
export default class PreviewButtonsDestructive extends Vue {
  alert(message) {
    window.alert(message)
  }
}
