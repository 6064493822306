





































































































import Component from "vue-class-component";
import Vue from "vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import Logo from "@/components/front/Logo.vue";
import LoadingButton from "@/components/ui/buttons/LoadingButton.vue";
import services from "@/services";
import { TenantUserDto } from "@/application/dtos/core/tenants/TenantUserDto";
import { UserVerifyRequest } from '@/application/contracts/core/users/UserVerifyRequest';
import { TenantDto } from '@/application/dtos/core/tenants/TenantDto';
import { TenantInvitationResponse } from '@/application/contracts/core/tenants/TenantInvitationResponse';
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("account.invitation.title").toString(),
  },
  components: {
    ErrorModal,
    Logo,
    LoadingButton
  }
})
export default class Invitation extends Vue {
  $refs!: {
    loadingButton: LoadingButton;
    errorModal: ErrorModal;
  };
  loading = false;
  requirePassword = true;
  user = {} as UserVerifyRequest;
  invitation: TenantUserDto | null = null;
  tenant = {} as TenantDto;
  created() {
    this.user.token = this.$route.query.i ? this.$route.query.i.toString() : "";
    this.user.email = this.$route.query.e ? this.$route.query.e.toString() : "";
  }
  mounted() {
    if (this.$route.query.i) {
      this.loading = true;
      services.tenantUserInvitations
        .getInvitation(this.$route.query.i.toString())
        .then((response: TenantInvitationResponse) => {
          this.invitation = response.invitation;
          this.tenant = response.tenant;
          this.requirePassword = response.requiresVerify;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  accept() {
    if (this.user.password !== this.user.passwordConfirm) {
      this.$refs.errorModal.show(this.$t("api.errors.passwordMismatch"));
      return;
    }

    this.$refs.loadingButton.start();
    services.tenantUserInvitations.acceptInvitation(this.$route.query.i?.toString() ?? "", this.user).catch((error) => {
      this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      if (this.$refs.loadingButton) {
        this.$refs.loadingButton.stop();
      }
    });
  }
}
