















































import Vue from "vue";
import Component from "vue-class-component";
import store from '@/store';
import { ApplicationLayout } from '@/application/enums/shared/ApplicationLayout';
import i18n from '@/locale/i18n';

@Component({})
export default class LayoutSelector extends Vue {
  open = false;
  dropDown = false;
  layouts = [
    {
      name: i18n.t("shared.layouts.sidebar"),
      value: ApplicationLayout.SIDEBAR
    },
    {
      name: i18n.t("shared.layouts.stacked"),
      value: ApplicationLayout.STACKED
    }
  ]
  mounted() {
    this.dropDown = this.open;
  }
  closedropDown() {
    this.dropDown = false;
  }
  select(value: ApplicationLayout) {
    this.closedropDown();
    store.commit("app/setLayout", value)
  }
}
