













































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import imageCompression from "browser-image-compression";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";

@Component({})
export default class UploadDocument extends Vue {
  @Prop({ default: ".pdf", type: String })
  accept!: string;
  @Prop({ default: false, type: Boolean })
  multiple!: boolean;
  @Prop({ default: "", type: String })
  description!: boolean;
  @Prop({ default: 0, type: Number })
  imageStyle!: number;

  isDragging = false;
  loading = false;

  dragOver() {
    if (!this.loading) {
      this.isDragging = true;
    }
  }
  dragLeave() {
    this.isDragging = false;
  }
  async compressFile(imageFile: File): Promise<any> {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920 / 2,
      useWebWorker: true,
    };
    try {
      const file = await imageCompression(imageFile, options);
      return Promise.resolve(file);
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async compressFileNotImage(imageFile: File): Promise<any> {
    return Promise.resolve(imageFile);
  }
  async drop(e) {
    let files: any[] = [...e.dataTransfer.files];
    const newImagesPromises: any[] = [];
    await files.forEach((element: File) => {
      if (element.type.includes("image")) {
        newImagesPromises.push(this.compressFile(element));
      } else {
        newImagesPromises.push(this.compressFileNotImage(element));
      }
    });
    files = await Promise.all(newImagesPromises);
    const filesArray: FileBase64[] = [];
    const promises: any[] = [];

    files.forEach((file) => {
      const promise = this.getBase64(file);
      promises.push(promise);
      promise
        .then((response) => {
          filesArray.push({
            file,
            base64: response,
          });
          this.$emit("dropped", response, file);
        })
        .catch((e) => {
          console.error(e);
        });
    });
    await Promise.all(promises).then(() => {
      this.$emit("droppedFiles", filesArray, files);
    });
    this.isDragging = false;
  }
  requestUploadFile() {
    const src = this.$el.querySelector("#uploadmyfile");
    this.drop({ dataTransfer: src });
  }
  getBase64(file) {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = (ev) => {
        resolve(ev?.target?.result);
      };
      reader.readAsDataURL(file);
    });
  }
  get getClasses() {
    return this.isDragging && !this.loading ? "bg-theme-200 border-2 border-dashed border-theme-800" : "";
  }
}
