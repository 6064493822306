











































import Vue from "vue";
import Component from "vue-class-component";
import supportedLocales from "../../../locale/supportedLocales";
import i18n from "@/locale/i18n";

@Component({})
export default class LocaleSelector extends Vue {
  open = false
  dropDown = false;
  supportedLocales = supportedLocales;
  mounted() {
    this.dropDown = this.open;
  }
  closedropDown() {
    this.dropDown = false;
  }
  select(value) {
    this.closedropDown();
    localStorage.setItem("locale", value)
    i18n.locale = value;
  }
}
