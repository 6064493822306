var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.to)?_c('button',{staticClass:"underline inline-flex space-x-2 items-center px-1 py-2 text-sm font-medium focus:outline-none",class:{
    'cursor-not-allowed opacity-75': _vm.disabled,
    'text-theme-600': !_vm.destructive,
    'text-gray-600': _vm.destructive,
    'hover:text-theme-800 focus:text-theme-900': !_vm.disabled && !_vm.destructive,
    'hover:text-gray-800 focus:text-gray-900': !_vm.disabled && _vm.destructive
  },attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2):_c('router-link',{staticClass:"underline inline-flex space-x-2 items-center px-1 py-2 text-sm font-medium focus:outline-none",class:{
    'cursor-not-allowed opacity-75': _vm.disabled,
    'text-theme-600': !_vm.destructive,
    'text-gray-600': _vm.destructive,
    'hover:text-theme-800 focus:text-theme-900': !_vm.disabled && !_vm.destructive,
    'hover:text-gray-800 focus:text-gray-900': !_vm.disabled && _vm.destructive
  },attrs:{"to":_vm.to,"disabled":_vm.disabled,"target":_vm.target}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }