







import { Vue, Component } from "vue-property-decorator";
import store from "./store";
import { Theme } from "./application/enums/shared/Theme";
import TopBanner from "./components/ui/banners/TopBanner.vue";

@Component({
  metaInfo: {
    titleTemplate: "%s | Vue2 SaasFrontend"
  },
  components: {
    TopBanner
  }
})
export default class App extends Vue {
  mounted() {
    const htmlClasses = document.querySelector("html")?.classList;
    if (this.currentTheme === 0) {
      htmlClasses?.remove("dark");
    } else {
      htmlClasses?.add("dark");
    }
  }
  get currentTheme(): Theme {
    return store.state?.theme?.theme ?? Theme.LIGHT;
  }
}
