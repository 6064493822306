import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";

export const appCoreRoutes = {
  path: "/app",
  component: require("@/views/core/Index.vue").default,
  meta: {
    requiresAuth: true,
  },
  redirect: "/app/dashboard",
  children: [
    {
      path: "dashboard",
      component: require("@/views/core/Dashboard.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER, TenantUserRole.GUEST],
      },
    },
    {
      path: "unauthorized",
      component: require("@/views/core/Unauthorized.vue").default,
    },
  ],
};
