











































import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";

export interface TabItem {
  name: any;
  routeName?: string;
  routePath?: string;
  routeParams?: any;
}

@Component({})
export default class Tabs extends Vue {
  @Prop({}) tabs!: TabItem[];
  @Prop({ default: true }) asLinks!: boolean;
  selected = 0;
  selectTab(idx) {
    const tab = this.tabs[idx];
    if (this.asLinks) {
      if (tab?.routeName) {
        this.$router.push({ name: tab.routeName, params: tab.routeParams });
      } else if (tab?.routePath) {
        this.$router.push(tab.routePath);
      }
    } else {
      this.selected = idx;
      this.$emit("selected", idx);
    }
  }
  isCurrent(idx: number) {
    return this.currentTab === this.tabs[idx];
  }
  getRoute(tab: TabItem) {
    return tab.routePath ??
    {
      name: tab.routeName,
      params: tab.routeParams,
    }
  }
  get currentTab() {
    if (this.asLinks) {
      return this.tabs.find((element) =>
        (element.routeName && this.$route.name === element.routeName)
        || (element.routePath && this.$route.path === element.routePath));
    } else {
      return this.tabs[this.selected]
    }
  }
}
