


















import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/front/Header.vue";
import Plans from "@/components/front/Plans.vue"
import Footer from "@/components/front/Footer.vue";
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("front.pricing.title").toString(),
  },
  components: {
    Header,
    Plans,
    Footer,
  },
})
export default class Pricing extends Vue {
}
