











































































import Component from "vue-class-component";
import Vue from "vue";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";
import Logo from "@/components/front/Logo.vue";
import LoadingButton from '@/components/ui/buttons/LoadingButton.vue';
import services from "@/services";
import { UserLoginRequest } from '@/application/contracts/core/users/UserLoginRequest';
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("account.login.title").toString(),
  },
  components: {
    ErrorModal,
    Logo,
    LoadingButton,
  }
})
export default class Login extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    loadingButton: LoadingButton;
  };
  loading = false;
  user = {} as UserLoginRequest;
  login() {
    this.$refs.loadingButton.start();
    this.loading = true;
    services.authentication
      .login(this.user)
      .catch((error) => {
        this.$refs.loadingButton.stop();
        this.$refs.errorModal.show(this.$t("shared.error"), this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
