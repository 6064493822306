















































































































































































































import Vue from "vue";
import Component from 'vue-class-component';
import Header from "@/components/front/Header.vue";
import Footer from "@/components/front/Footer.vue";
import WarningBannner from "@/components/ui/banners/WarningBanner.vue";
import i18n from '@/locale/i18n';

@Component({
  metaInfo: {
    title: i18n.t("front.contact.title").toString(),
  },
  components: {
    Header,
    Footer,
    WarningBannner
  },
})
export default class Contact extends Vue {
  usersOptions = ["1", "2 - 3", "4 - 10", "11 - 25", "26 - 50", "51 - 100", "+100"];
  sent = false;
  firstName = "";
  lastName = "";
  email = "";
  organization = "";
  users = "1";
  comments = "";
  actionURL = "";
  mounted() {
    this.actionURL = process.env.VUE_APP_INTEGRATIONS_CONTACT_FORMSPREE ?? "";
  }
}
