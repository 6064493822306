



































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import WorkspaceSelector from "@/components/layouts/selectors/WorkspaceSelector.vue";
import TenantSelector from "@/components/layouts/selectors/TenantSelector.vue";
import store from "@/store";
import PendingInvitationsButton from "./buttons/PendingInvitationsButton.vue";
import QuickActionsButton from "./buttons/QuickActionsButton.vue";
import ProfileButton from "./buttons/ProfileButton.vue";
import ChatSupportButton from "./buttons/ChatSupportButton.vue";
import SidebarMenu from "./SidebarMenu.vue";
import LocaleSelector from "@/components/ui/selectors/LocaleSelector.vue";
import LayoutSelector from "@/components/ui/selectors/LayoutSelector.vue";

@Component({
  components: {
    WorkspaceSelector,
    TenantSelector,
    PendingInvitationsButton,
    QuickActionsButton,
    ProfileButton,
    ChatSupportButton,
    SidebarMenu,
    LocaleSelector,
    LayoutSelector
  },
})
export default class SidebarLayout extends Vue {
  $refs!: {
    mainElement: HTMLElement;
  };
  @Prop({ default: '' }) layout!: string;
  sidebarOpen = false;

  get currentWorkspaceId(): string {
    return store.state.tenant.currentWorkspace?.id ?? "";
  }
}
