var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.to)?_c('button',{staticClass:"inline-flex items-center space-x-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2",class:{
    'cursor-not-allowed opacity-75': _vm.disabled,
    'bg-theme-600': !_vm.destructive,
    'bg-red-600': _vm.destructive,
    'hover:bg-theme-700 focus:ring-theme-500': !_vm.disabled && !_vm.destructive,
    'hover:bg-red-700 focus:ring-red-500': !_vm.disabled && _vm.destructive
  },attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2):_c('router-link',{staticClass:"inline-flex items-center space-x-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white",class:{
    'cursor-not-allowed opacity-75': _vm.disabled,
    'focus:outline-none focus:ring-2 focus:ring-offset-2': !_vm.disabled,
    'bg-theme-600': !_vm.destructive,
    'bg-red-600': _vm.destructive,
    'hover:bg-theme-700 focus:ring-theme-500': !_vm.disabled && !_vm.destructive,
    'hover:bg-red-700 focus:ring-red-500': !_vm.disabled && _vm.destructive
  },attrs:{"to":_vm.to,"disabled":_vm.disabled,"target":_vm.target}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }