


















import Vue from "vue";
import Component from "vue-class-component";
import ButtonSecondary from "../buttons/ButtonSecondary.vue";
import SuccessModal from "./SuccessModal.vue";
import ErrorModal from "./ErrorModal.vue";
import ConfirmModal from "./ConfirmModal.vue";
import Modal from "./Modal.vue";

@Component({
  components: {
    ButtonSecondary,
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    Modal
  }
})
export default class PreviewModals extends Vue {
  $refs!: {
    successModal: SuccessModal;
    errorModal: ErrorModal;
    confirmModal: ConfirmModal;
    emptyModal: Modal;
  }
  showSuccessModal() {
    this.$refs.successModal.show('Title', 'Description...');
  }
  showErrorModal() {
    this.$refs.errorModal.show('Title', 'Description...');
  }
  showConfirmModal() {
    this.$refs.confirmModal.show('Title', 'Confirm', 'Cancel', 'Description...');
  }
  showEmptyModal() {
    this.$refs.emptyModal.show();
  }
  alert(message) {
    window.alert(message)
  }
}
