


























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SidebarLayout from "@/components/layouts/SidebarLayout.vue";
import StackedLayout from "@/components/layouts/StackedLayout.vue";
import store from "@/store";
import { ApplicationLayout } from "@/application/enums/shared/ApplicationLayout";

@Component({
  components: {
    SidebarLayout,
    StackedLayout,
  },
})
export default class AppLayout extends Vue {
  @Prop({}) layout!: string;
  get currentWorkspaceId(): string {
    return store.state.tenant.currentWorkspace?.id ?? "";
  }
  get currentLayout(): ApplicationLayout {
    return store.state.app.layout;
  }
}
