





















































































































import Vue from "vue";
import Component from "vue-class-component";
import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";
import store from "@/store";
import tinyEventBus from "@/plugins/tinyEventBus";

@Component({
  components: {}
})
export default class QuickActionsButton extends Vue {
  showQuickActions = false;

  closeShowQuickActions() {
    this.showQuickActions = false;
  }
  newProvider() {
    this.showQuickActions = false;
    tinyEventBus().emitter.emit("new-provider");
  }
  newClient() {
    this.showQuickActions = false;
    tinyEventBus().emitter.emit("new-client");
  }
  get isOwnerOrAdmin(): boolean {
    return (
      this.currentRole === TenantUserRole.OWNER ||
      this.currentRole === TenantUserRole.ADMIN
    );
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
}
