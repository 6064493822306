















































import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store";
import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";
import tinyEventBus from "@/plugins/tinyEventBus";
import services from "@/services";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";

@Component({
  components: {},
})
export default class PendingInvitationsButton extends Vue {
  showPendingLinks() {
    tinyEventBus().emitter.emit("view-pending-invitations");
  }
  mounted() {
    services.tenants.getCurrentUsage(AppUsageType.PENDING_INVITATIONS);
  }
  get pendingInvitations(): number {
    return store.state.app.usage.pendingInvitations;
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
}
