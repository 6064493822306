



























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from 'vue-property-decorator';

interface MenuItem {
  routeName?: string;
  routePath?: string;
  title: string;
  params?: any;
  query?: any;
}

@Component({
  components: {
  },
})
export default class Breadcrumb extends Vue {
  @Prop({ default: "" }) home!: string;
  @Prop({}) menu!: MenuItem[];
  homeRoute = "/app";
  mounted() {
    this.homeRoute = this.home;
  }
}
