var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-2 w-full"},[_c('EmptyState',{attrs:{"captions":{
      new: 'Button',
      thereAreNo: 'There are no...',
      description: 'Description...',
    },"icon":"plus"},on:{"new":function($event){return _vm.alert('Clicked')}}}),_c('EmptyState',{attrs:{"to":_vm.currentRoute,"captions":{
      new: 'Link',
      thereAreNo: 'There are no...',
      description: 'Description...',
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }