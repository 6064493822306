





























































































































import Vue from "vue";
import Component from "vue-class-component";
import { SubscriptionProductDto } from "@/application/dtos/core/subscriptions/SubscriptionProductDto";
import { SubscriptionPriceDto } from "@/application/dtos/core/subscriptions/SubscriptionPriceDto";
import { SubscriptionBillingPeriod } from "@/application/enums/core/subscriptions/SubscriptionBillingPeriod";
import { Prop } from "vue-property-decorator";
import store from "@/store";
import services from "@/services";
import NumberUtils from "@/utils/shared/NumberUtils";
import TenantUtils from "@/utils/store/TenantUtils";
import Loading from "@/components/ui/loaders/Loading.vue";
import WarningBanner from "../banners/WarningBanner.vue";

@Component({
  components: {
    Loading,
    WarningBanner
  },
})
export default class PlansRadioButtons extends Vue {
  @Prop({ default: true }) plansLabel!: boolean;
  @Prop({ default: false }) showCurrent!: boolean;

  loading = false;
  created() {
    if (store.state.pricing.products.length === 0) {
      services.subscriptionProducts.getProducts().then(() => {
        this.loadCurrent();
      });
    }
  }
  mounted() {
    this.loading = true;
    services.subscriptionProducts
      .getProducts()
      .then(() => {
        this.loadCurrent();
        if (!store.state.auth.authenticated) {
          this.selectProductIfNotSelected();
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  loadCurrent() {
    if (this.activeProduct) {
      store.commit("pricing/setSelected", {
        billingPeriod: this.activeProduct.subscriptionPrice.billingPeriod,
        product: this.activeProduct.subscriptionProduct,
      });
    }
  }
  getPrice(product: SubscriptionProductDto): SubscriptionPriceDto | undefined {
    const prices = product.prices.find((f) => (f.billingPeriod === store.state.pricing.billingPeriod || f.billingPeriod === SubscriptionBillingPeriod.ONCE) && f.currency === store.state.pricing.currency && f.active);
    return prices;
  }
  getPriceAmount(product): string {
    return NumberUtils.intFormat(this.getPrice(product)?.price ?? 0);
  }
  changedProduct(e) {
    const product = this.products.find((f) => f.title === e.target.value);
    if (product) {
      store.commit("pricing/setSelected", {
        product,
        billingPeriod: store.state.pricing.billingPeriod,
      });
    }
  }
  selectMonthly() {
    store.commit("pricing/setBillingPeriod", SubscriptionBillingPeriod.MONTHLY);
  }
  selectYearly() {
    store.commit("pricing/setBillingPeriod", SubscriptionBillingPeriod.YEARLY);
  }
  toggleBillingPeriod() {
    if (store.state.pricing.billingPeriod === SubscriptionBillingPeriod.MONTHLY) {
      this.selectYearly();
    } else {
      this.selectMonthly();
    }
  }
  selectProductIfNotSelected() {
    if (this.products.length > 0) {
      if (!this.products.find((f) => f.id === this.selectedProduct?.id)) {
        store.commit("pricing/setSelected", {
          billingPeriod: this.products[0].prices[0].billingPeriod,
          product: this.products[0],
        });
      }
    }
  }
  getBillingPeriodName(billingPeriod: SubscriptionBillingPeriod) {
    return this.$t("pricing." + SubscriptionBillingPeriod[billingPeriod]);
  }
  getYearlyDiscount(): string | undefined {
    const priceYearly = this.getPriceWithInterval(SubscriptionBillingPeriod.YEARLY);
    const priceMonthly = this.getPriceWithInterval(SubscriptionBillingPeriod.MONTHLY);
    if (priceYearly && priceMonthly) {
      const discount = 100 - (priceYearly.price * 100) / (priceMonthly.price * 12);
      if (discount !== 0) {
        return "-" + discount.toFixed(0) + "%";
      }
    }

    return undefined;
  }
  getPriceWithInterval(billingPeriod: SubscriptionBillingPeriod): SubscriptionPriceDto | undefined {
    let price: SubscriptionPriceDto | undefined;
    if (this.products && this.products.length > 0) {
      this.products.forEach((product) => {
        const prices = product.prices.find((f) => f.billingPeriod === billingPeriod && f.currency === store.state.pricing.currency && f.price > 0);
        if (prices) {
          price = prices;
        }
      });
    }
    return price;
  }
  isSelected(product: SubscriptionProductDto) {
    return this.selectedProduct && product.title === this.selectedProduct.title
  }
  intFormat(value) {
    return NumberUtils.intFormat(value)
  }
  get billingPeriod() {
    return store.state.pricing.billingPeriod;
  }
  get activeProduct() {
    return TenantUtils.activeProduct();
  }
  get selectedProduct(): SubscriptionProductDto | null {
    return store.state.pricing.selectedProduct;
  }
  get products() {
    const products = (store.state.pricing.products as SubscriptionProductDto[])
      ?.filter((f) => f.active && !f.contactUs)
      .sort((x, y) => {
        return x.tier > y.tier ? 1 : -1;
      });
    return products;
  }
}
