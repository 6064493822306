























































































































import Vue from "vue";
import Component from "vue-class-component";
import { UserType } from "@/application/enums/core/users/UserType";
import store from "@/store";
import { TenantUserRole } from "@/application/enums/core/tenants/TenantUserRole";
import { Prop } from "vue-property-decorator";
import { AdminSidebar } from "@/application/sidebar/AdminSidebar";
import { AppSidebar } from "@/application/sidebar/AppSidebar";
import { SideBarItem } from "@/application/sidebar/SidebarItem";
import { SidebarGroup } from "@/application/sidebar/SidebarGroup";
import SidebarMenu from "./SidebarMenu.vue";
import ProfileButton from "./buttons/ProfileButton.vue";
import LocaleSelector from "@/components/ui/selectors/LocaleSelector.vue";
import LayoutSelector from "@/components/ui/selectors/LayoutSelector.vue";
import QuickActionsButton from "./buttons/QuickActionsButton.vue";

@Component({
  components: {
    ProfileButton,
    LocaleSelector,
    LayoutSelector,
    SidebarMenu,
    QuickActionsButton
  },
})
export default class StackedLayout extends Vue {
  @Prop()
  layout!: string;

  menu: SideBarItem[] = [];
  sidebarOpen = false;
  mounted() {
    if (this.layout === "admin") {
      this.menu = AdminSidebar;
    } else {
      this.menu = AppSidebar;
    }
  }
  allowCurrentUserType(types: UserType[] | undefined) {
    return (!types || types.includes(this.currentUserType));
  }
  allowCurrentRole(roles: TenantUserRole[] | undefined) {
    return (!roles || roles.includes(this.currentUserRole));
  }
  logout() {
    store.commit("auth/logout");
  }
  get getMenu(): SidebarGroup[] {
    const menu: SidebarGroup[] = [];
    this.menu
      .filter(f => this.allowCurrentUserType(f.userTypes)
        && this.allowCurrentRole(f.userRoles))
      .forEach(({ title, items }) => {
        menu.push({
          title: title.toString(),
          items: items?.filter(f => this.allowCurrentUserType(f.userTypes)
            && this.allowCurrentRole(f.userRoles)) ?? [],
        });
      });
    return menu.filter(f => f.items.length > 0);
  }
  get currentUserType(): UserType {
    return store.state.account.user?.type ?? UserType.Tenant;
  }
  get currentUserRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role as TenantUserRole;
  }
}
