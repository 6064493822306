<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="30"
    height="30"
    viewBox="0 0 172 172"
    fill="currentColor"
  >
    <g
      fill="none"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none" />
      <g fill="currentColor">
        <path
          d="M51.6,17.2c-15.76389,0 -28.66667,12.90277 -28.66667,28.66667v5.73333c0.00032,3.1663 2.56703,5.73302 5.73333,5.73333h63.06667c3.1663,-0.00032 5.73302,-2.56703 5.73333,-5.73333v-5.73333c0,-9.56597 7.63403,-17.2 17.2,-17.2c9.56597,0 17.2,7.63403 17.2,17.2v80.26667h-5.73333c0,4.0248 -0.73162,7.8776 -2.00442,11.46667h12.50807c1.67906,0.28606 3.39838,-0.18915 4.6922,-1.2969c1.29381,-1.10775 2.02818,-2.73336 2.00416,-4.43644v-86c0,-15.76389 -12.90277,-28.66667 -28.66667,-28.66667zM40.13333,68.8v63.06667c0,3.1648 2.56853,5.73333 5.73333,5.73333h13.47109c-1.2728,-3.58907 -2.00442,-7.44187 -2.00442,-11.46667h-5.73333v-57.33333zM74.53333,80.26667c-2.06765,-0.02924 -3.99087,1.05709 -5.03322,2.843c-1.04236,1.78592 -1.04236,3.99474 0,5.78066c1.04236,1.78592 2.96558,2.87225 5.03322,2.843h34.4c2.06765,0.02924 3.99087,-1.05709 5.03322,-2.843c1.04236,-1.78592 1.04236,-3.99474 0,-5.78066c-1.04236,-1.78592 -2.96558,-2.87225 -5.03322,-2.843zM91.73333,103.2c-12.59782,0 -22.93333,10.33551 -22.93333,22.93333c0,5.73428 2.21189,10.93616 5.73333,14.97161v30.89505l17.2,-11.46667l17.2,11.46667v-30.89505c3.52144,-4.03546 5.73333,-9.23733 5.73333,-14.97161c0,-12.59782 -10.33551,-22.93333 -22.93333,-22.93333zM91.73333,114.66667c6.40077,0 11.46667,5.06589 11.46667,11.46667c0,6.40077 -5.06589,11.46667 -11.46667,11.46667c-6.40077,0 -11.46667,-5.06589 -11.46667,-11.46667c0,-6.40077 5.06589,-11.46667 11.46667,-11.46667z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {

}
</script>

<style>
</style>