


















import Vue from "vue";
import Component from "vue-class-component";
import InfoBanner from "./InfoBanner.vue";
import WarningBanner from "./WarningBanner.vue";

@Component({
  components: {
    InfoBanner,
    WarningBanner
  },
})
export default class PreviewBanners extends Vue {

}
