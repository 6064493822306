































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class Dropdown extends Vue {
  @Prop({}) right!: boolean;
  opened = false;

  close() {
    this.opened = false;
  }
}
