






































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  showing = false;
  show() {
    this.showing = true;

    this.$nextTick(() => {
      window.addEventListener("keyup", this.keyup);
    });
  }
  keyup(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
}
