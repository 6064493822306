














import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ButtonPrimary from "./ButtonPrimary.vue";

@Component({
  components: {
    ButtonPrimary
  }
})
export default class LoadingButton extends Vue {
  @Prop({}) disabled!: boolean;
  loading = false;
  start() {
    this.loading = true;
  }
  stop() {
    this.loading = false;
  }
}
