
















































































import Vue from "vue";
import Component from "vue-class-component";
import { TranslateResult } from "vue-i18n";

@Component
export default class SuccessModal extends Vue {
  $refs!: {
    btnClose: HTMLButtonElement;
  };
  title: TranslateResult = "";
  description: TranslateResult = "";
  closeText: TranslateResult = "";
  showing = false;
  mounted() {
    this.title = this.$t("shared.success");
    this.closeText = this.$t("shared.close");
  }
  show(title?: TranslateResult, description?: TranslateResult) {
    if (title) {
      this.title = title;
    }
    if (description) {
      this.description = description;
    }
    this.showing = true;
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.btnClose) {
          this.$refs.btnClose.focus();
        }
      }, 500);
    });
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
}
