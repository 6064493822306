







































































































import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store";
import { UserDto } from "@/application/dtos/core/users/UserDto";

@Component({
  components: {}
})
export default class ProfileButton extends Vue {
  dropDownUser = false;
  closeDropdownUser() {
    this.dropDownUser = false;
  }
  logout() {
    store.commit("auth/logout");
  }
  get user(): UserDto | null {
    return store.state.account.user;
  }
  get avatar(): string {
    return this.user?.avatar ?? "";
  }
  get email() {
    return this.user?.email ?? "";
  }
  get profileName(): string {
    if (this.user) {
      if (this.user.firstName && this.user.lastName) {
        return this.user.firstName + " " + this.user.lastName;
      } else {
        return this.user.email;
      }
    }
    return "--";
  }
}
