






















import Vue from "vue";
import Component from "vue-class-component";
import ButtonPrimary from './ButtonPrimary.vue'
import ButtonSecondary from './ButtonSecondary.vue'
import ButtonTertiary from './ButtonTertiary.vue'
import LoadingButton from './LoadingButton.vue'

@Component({
  components: {
    ButtonPrimary,
    ButtonSecondary,
    ButtonTertiary,
    LoadingButton
  }
})
export default class PreviewButtons extends Vue {
  $refs!: {
    loadingButton: LoadingButton;
  }
  startLoading() {
    if (this.$refs.loadingButton) {
      this.$refs.loadingButton?.start()
      setTimeout(() => {
        this.$refs.loadingButton?.stop()
      }, 2000)
    }
  }
  alert(message) {
    window.alert(message)
  }
}
